import React from "react"
import Header from "./header"
import Footer from "./footer"

import "./css-imports"

const Layout = ({ children }) => {
  return (
    <div className="[ viewport ]">
      <Header />

      <main className="[ subgrid ]">{children}</main>

      <Footer />
    </div>
  )
}

export default Layout
