/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  description,
  lang,
  meta,
  title,
  twitterImage,
  openGraphImage,
  postData,
  canonicalLink,
}) => {
  const { site, socialImage } = useStaticQuery(
    graphql`
      query {
        socialImage: file(absolutePath: { regex: "/share.jpg/" }) {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        site {
          siteMetadata {
            title
            siteUrl
            description
            author {
              name
            }
            social {
              twitter
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const titleString = `${title} | ${site.siteMetadata.title}`

  const absolutePath = path =>
    `${site.siteMetadata.siteUrl}${`/${path}`.replace(/\/\//g, "/")}`

  const siteImageDescription = `A dark background with a cyan and pink, angular logo. Looks dope.`
  const siteImage = absolutePath(socialImage.childImageSharp.fixed.src)

  const twitterMeta = [
    {
      name: `twitter:creator`,
      content: site.siteMetadata.social.twitter,
    },
    {
      name: `twitter:title`,
      content: titleString,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    { name: `twitter:card`, content: `summary_large_image` },
    ...(!!twitterImage
      ? [
          { name: `twitter:image`, content: absolutePath(twitterImage.image) },
          { name: `twitter:image:alt`, content: twitterImage.altText },
        ]
      : [
          { name: `twitter:image`, content: siteImage },
          {
            name: `twitter:image:alt`,
            content: siteImageDescription,
          },
        ]),
  ]

  const postImageData = image =>
    !!image
      ? [
          { property: `og:image`, content: absolutePath(image.image) },
          { property: `og:image-alt`, content: image.altText },
        ]
      : [
          { property: `og:image`, content: siteImage },
          { property: `og:image-alt`, content: siteImageDescription },
        ]

  const openGraphMeta = [
    {
      property: `og:title`,
      content: titleString,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    ...(!!postData
      ? [
          ...postImageData(openGraphImage),
          { property: `og:url`, content: absolutePath(postData.path) },
          { property: `og:type`, content: `article` },
          {
            property: `og:article:published_time`,
            content: postData.published,
          },
          !!postData.modified
            ? {
                property: `og:article:modified_time`,
                content: postData.modified,
              }
            : {},
          {
            property: `og:article:author`,
            content: postData.author || site.siteMetadata.author.name,
          },
          ...postData.tags.map(x => ({
            property: `og:article:tag`,
            content: x,
          })),
        ]
      : [
          {
            property: `og:image`,
            content: absolutePath(
              site.siteMetadata.social.openGraphDefaultImage,
            ),
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ]),
  ]

  const links = canonicalLink ? [{ rel: "canonical", href: canonicalLink }] : []

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={links}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        ...openGraphMeta,
        ...twitterMeta,
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  twitterImage: PropTypes.object,
  openGraphImage: PropTypes.object,
  postData: PropTypes.object,
}

export default SEO
