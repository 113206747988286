import React from "react"
import RssSvg from "./rss"
import TwitterSvg from "./twitter"
import GitLabSvg from "./gitlab"

const Icon = ({ children }) => {
  return <span className="[ icon-container ]">{children}</span>
}

export const Rss = () => (
  <Icon>
    <RssSvg />
  </Icon>
)
export const Twitter = () => (
  <Icon>
    <TwitterSvg />
  </Icon>
)
export const GitLab = () => (
  <Icon>
    <GitLabSvg />
  </Icon>
)
