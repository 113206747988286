import React from "react"

export default function () {
  return (
    <svg
      width="1000"
      height="1000"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="icon"
    >
      <path
        d="M309 818C309 889 252 946 182 946 111 946 54 889 54 818 54 748 111 691 182 691 252 691 309 748 309 818V818ZM659 913C643 604 396 357 87 341 69 340 54 354 54 372V468C54 485 67 499 84 500 307 515 485 693 500 916 501 933 515 946 532 946H628C646 946 660 931 659 913ZM947 913C930 447 555 70 87 53 69 52 54 67 54 85V181C54 198 68 212 85 213 466 228 772 534 787 915 788 932 802 946 819 946H915C933 946 948 931 947 913V913Z"
        fill="currentColor"
      />
    </svg>
  )
}
