import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import HeavySeparator from "./heavy-separator"

const NavLink = ({ target, text }) => {
  return (
    <Link to={target} activeClassName="[ active ]">
      {text}
    </Link>
  )
}

export const Component = ({ siteTitle }) => {
  const navTargets = [
    ["home", "/"],
    ["posts", "/posts"],
  ]

  return (
    <header className="[ header-footer ]">
      <div className="[ header-content ]">
        <span className="[ logo ]">
          <Link to="/">{siteTitle}</Link>
        </span>
        <nav className="[ header-nav ]">
          <ul className="[ inline-list ]">
            {navTargets.map(([pageName, url]) => (
              <li key={pageName}>
                <NavLink target={url} text={pageName} />
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <HeavySeparator />
    </header>
  )
}

export default function () {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return <Component siteTitle={site.siteMetadata.title} />
}
