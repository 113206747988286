import React from "react"

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className="icon"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="currentColor"
          d="M8.048 21.5c9.056 0 14.01-7.503 14.01-14.01 0-.212 0-.425-.015-.636A10.02 10.02 0 0024.5 4.306a9.829 9.829 0 01-2.828.774 4.94 4.94 0 002.165-2.723 9.868 9.868 0 01-3.127 1.195 4.929 4.929 0 00-8.391 4.49A13.98 13.98 0 012.17 2.899a4.927 4.927 0 001.525 6.573 4.888 4.888 0 01-2.235-.616v.062a4.925 4.925 0 003.95 4.827 4.917 4.917 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.88 9.88 0 01.5 19.287a13.941 13.941 0 007.548 2.208"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M.5 0h24v24H.5z" />
        </clipPath>
      </defs>
    </svg>
  )
}
