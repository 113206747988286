import React from "react"
import HeavySeparator from "./heavy-separator"
import { Twitter, GitLab, Rss } from "../icons/index.jsx"

const IconLi = ({ href, ariaLabel, Icon }) => {
  return (
    <li>
      <a href={href} aria-label={ariaLabel}>
        <Icon />
      </a>
    </li>
  )
}

export default function () {
  const icons = [
    {
      href: "/rss.xml",
      label: "RSS icon. Link to the site's RSS feed.",
      icon: Rss,
    },
    {
      href: "https://gitlab.com/thomasheartman/blog",
      label: "GitLab icon. Link to the source code for this repo.",
      icon: GitLab,
    },
    {
      href: "https://twitter.com/thomasheartman",
      label: "Twitter icon. Link to my twitter profile. Come say hi!",
      icon: Twitter,
    },
  ]
  return (
    <footer className="[ header-footer ]">
      <HeavySeparator />

      <div className="[ footer-content ]">
        <ul className="[ footer-icons ][ inline-list ]">
          {icons.map(({ href, label, icon }) => (
            <IconLi key={href} href={href} ariaLabel={label} Icon={icon} />
          ))}
        </ul>
        <span className="copyright">
          © Thomas Heartman {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  )
}
