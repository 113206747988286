import React from "react"

export default function () {
  return (
    <svg
      width="1000"
      height="1000"
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="icon"
    >
      <path
        d="M980 554L929 399C929 397 929 396 928 394L827 82C824 73 819 66 812 62 805 57 797 54 788 54 780 54 771 57 764 62 758 67 752 74 750 82L653 380H347L250 82C248 74 243 67 236 62 229 57 221 54 212 54H212C203 54 195 57 188 62 181 67 176 74 173 82L72 395C72 395 72 396 71 396L20 554C16 566 16 578 20 590 24 602 31 612 41 619L486 943C490 946 495 947 500 947 505 947 510 945 513 943L959 619C969 612 976 602 980 590 984 578 984 566 980 554ZM314 426L438 808 140 426H314ZM562 808L681 441 686 426H860L591 771 562 808ZM788 111L876 380H701L788 111ZM638 425L552 692 500 850 362 425H638ZM212 111L299 380H125L212 111ZM68 582C66 581 64 578 63 576 62 573 62 571 63 568L102 451 382 811 68 582ZM932 582L618 811 619 809 898 451 937 568C938 571 938 573 937 576 936 578 934 581 932 582"
        fill="currentColor"
      />
    </svg>
  )
}
